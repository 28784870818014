
.Name {
    font-size: calc(16px + 1vmin);
    font-weight: bold;
    color: black;
    line-height: 80%;
    margin-bottom: calc(4px + 1vmin);
  }

.Subtitle {
    font-size: calc(10px + 1vmin);
    line-height: 80%;
}
